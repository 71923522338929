import React, { useEffect, useState } from 'react'

export const ThemeContext = React.createContext()



export default function ThemeProvider({children}){
       
    const[mode, setMode] = useState('')
    useEffect(()=>{
        if(window !== 'underfined'){        
            let md = window.localStorage.getItem('mode')
            setMode(md)
          }
    }, [mode])
   
    const createMode=(myMode)=>{        
        setMode(myMode)
    }
    return(<ThemeContext.Provider value={{      
        createModeFn: createMode,
        mode
    }}>{children}</ThemeContext.Provider>)
}