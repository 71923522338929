import { useEffect } from 'react'
import 'bootstrap/dist/css/bootstrap.css'
import SSRProvider from 'react-bootstrap/SSRProvider';
import '../styles/globals.css'
import BaseLayout from '../components/layouts/baseLayout';
import ThemeProvider from '../context/theme';
import FirebaseAuthProvider from '../context/firebaseAuthContext'
import { useRouter } from 'next/router';

import Script from 'next/script'
import * as gtag from '../lib/gtag'


function MyApp({ Component, pageProps: { session, ...pageProps }, }) {

    const router = useRouter()

    useEffect(() => {
        const handleStart = (url) => {

            const transaction = localStorage.getItem('sallestransactionhash')
            if (transaction !== null && url !== `/thankYouForPurchasing?search=${transaction}`) {
                window.localStorage.removeItem('sallestransactionhash')
            }
        }

        router.events.on('routeChangeStart', handleStart)

        return () => {
            router.events.off('routeChangeStart', handleStart)
        }
    }, [router])

    useEffect(() => {
        const handleRouteChange = (url) => {
            gtag.pageview(url)
        }
        router.events.on('routeChangeComplete', handleRouteChange)
        router.events.on('hashChangeComplete', handleRouteChange)
        return () => {
            router.events.off('routeChangeComplete', handleRouteChange)
            router.events.off('hashChangeComplete', handleRouteChange)
        }
    }, [router.events])

    return (<>
        <Script
            strategy="afterInteractive"
            src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`}
        />
        <Script
            id="gtag-init"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
                __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${gtag.GA_TRACKING_ID}', {
              page_path: window.location.pathname,
            });
          `,
            }}
        />
        <SSRProvider>
            <ThemeProvider>
                <FirebaseAuthProvider>         
                        <BaseLayout >
                            <Component {...pageProps} />
                        </BaseLayout>                   
                </FirebaseAuthProvider>
            </ThemeProvider>
        </SSRProvider>
    </>
    )
}

export default MyApp
