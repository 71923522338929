import { useContext } from 'react'
import Head from 'next/head'
import dynamic from 'next/dynamic'
import { ThemeContext } from '../../context/theme'
const Navigation = dynamic(() => import('../shared/navigation'))
const MyFooter = dynamic(() => import('../shared/footer'))


export default function BaseLayout(props) {

    const themeApi = useContext(ThemeContext)

    return (
        <div className={themeApi?.mode}>
            <Head>
                <link rel="icon" href="/favicon.ico" />
                <meta property="og:type" content="website" />


                {/*<link rel="apple-touch-icon" sizes="57x57" href="/images/icons/apple-icon-57x57.png" />*/}
                {/*<link rel="apple-touch-icon" sizes="60x60" href="/images/icons/apple-icon-60x60.png" />*/}
                {/*<link rel="apple-touch-icon" sizes="72x72" href="/images/icons/apple-icon-72x72.png" />*/}
                {/*<link rel="apple-touch-icon" sizes="76x76" href="/images/icons/apple-icon-76x76.png" />*/}
                {/*<link rel="apple-touch-icon" sizes="114x114" href="/images/icons/apple-icon-114x114.png" />*/}
                {/*<link rel="apple-touch-icon" sizes="120x120" href="/images/icons/apple-icon-120x120.png" />*/}
                {/*<link rel="apple-touch-icon" sizes="144x144" href="/images/icons/apple-icon-144x144.png" />*/}
                {/*<link rel="apple-touch-icon" sizes="152x152" href="/images/icons/apple-icon-152x152.png" />*/}
                {/*<link rel="apple-touch-icon" sizes="180x180" href="/images/icons/apple-icon-180x180.png" />*/}
                {/*<link rel="icon" type="image/png" sizes="192x192" href="/images/icons/android-icon-192x192.png" />*/}
                {/*<link rel="icon" type="image/png" sizes="32x32" href="/images/icons/favicon-32x32.png" />*/}
                {/*<link rel="icon" type="image/png" sizes="96x96" href="/images/icons/favicon-96x96.png" />*/}
                {/*<link rel="icon" type="image/png" sizes="16x16" href="/images/icons/favicon-16x16.png" />*/}
                {/*<link rel="manifest" href="/images/icons/manifest.json" />*/}
                {/*<meta name="msapplication-TileColor" content="#ffffff" />*/}
                {/*<meta name="msapplication-TileImage" content="/images/icons/ms-icon-144x144.png" />*/}
                {/*<meta name="theme-color" content="#ffffff" />*/}






                <link rel="apple-touch-icon" sizes="180x180" href="/images/icons2/apple-touch-icon.png" />
                <link rel="icon" type="image/png" sizes="32x32" href="/images/icons2/favicon-32x32.png" />
                <link rel="icon" type="image/png" sizes="16x16" href="/images/icons2/favicon-16x16.png" />
                <link rel="manifest" href="/images/icons2/site.webmanifest" />
                <meta name="msapplication-TileColor" content="#da532c" />
                <meta name="theme-color" content="#ffffff" />











            </Head>
            <Navigation />
            <main className='main'>
                {props.children}
            </main>
            <MyFooter />
        </div>
    )
}
